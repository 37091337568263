@import '../variables.scss';

.wholesale-product-page {
    display: flex;
    padding-top: 160px;
    max-width: 1232px;
    margin: 0 auto;
    width: 100%;

    .image-container {
        width: 50%;
        display: flex;
        flex-direction: column;

        .image {
            margin-bottom: 16px;
            width: 100%;
            height: 600px;
            display: flex;
            align-items: flex-start;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
    }

    .content-container {
        width: 50%;
        display: flex;
        flex-direction: column;
        margin-left: 48px;

        .page-tittle {
            position: relative;
            font-style: normal;
            font-weight: bold;
            font-size: 40px;

            border-bottom: 1px solid $text-light-gray;
            padding-bottom: 32px;
        }

        .color-picker-wrapper {
            margin-top: 32px;

            .button {
                margin: 20px 0 0 0;
            }
            .second-tittle {
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 120%;
                margin: 0 0 24px 0;
                text-transform: uppercase;
            }

            .color-picker-container {
                .colors-wrapper {
                    display: flex;
                    flex-wrap: wrap;

                    @media (max-width: 768px) {
                        flex-wrap: nowrap;
                        overflow-x: scroll;
                    }
                }
                .color-picker-item {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    width: 50%;
                    margin-bottom: 12px;

                    @media (max-width: 1000px) {
                        width: 100%;
                    }

                    @media (max-width: 768px) {
                        width: auto;
                        margin-right: 16px;
                        .text {
                            display: none;
                        }
                    }
                    
                }
            }
        }

        li {
            color: $text-dark-gray;
            list-style: none;
            font-family: Arial;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
        }

        .properties-wrapper {
            margin-top: 64px;

            .properties-container {
                margin-top: 24px;

                table {
                    width: 100%;
                    //border: 1;
                    font-family: Arial;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;

                    tr {

                        td {
                            color: $text-dark-gray;
                            //border: 1;
                            text-align: left;

                            &:first-child {
                                color: $text-light-gray;
                            }
                        }
                    }

                    td {
                        padding: 8px 0;
                    }
                }

                p {
                    color: $text-dark-gray;
                    font-family: Arial;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;
                }
            }
        }

        .description-wrapper {
            margin-top: 64px;

            p {
                color: $text-dark-gray;
            }
        }
    }

    @media (max-width: 1281px) {
        max-width: 992px;
    }

    @media (max-width: 1000px) {
        max-width: 768px;
    }
    
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 160px 16px 0;

        .image-container {
            width: 100%;

            .image {
            height: 350px;
            }
        }

        .content-container {
            width: 100%;
            margin-left: 0;
        }
    }
}