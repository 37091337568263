@import "../../../assets/scss/variables";

.region-modal {
  height: 100vh;
  width: 100%;
  z-index: 1000;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;

  .modal-content {
    background-color: $bg-wight;
    display: flex;
    flex-direction: column;
    padding: 40px;

    .title {
     margin-bottom: 20px;
    }

    .region {
      align-self: center;
    }

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn {
        border: none;
        cursor: pointer;
      }

      .dropdown {
        display: flex;
        position: relative;
        width: fit-content;
        line-height: 120%;
        cursor: pointer;

        &:hover {
          p {
            color: $color-red;
          }
        }

        p {
          font-size: 18px;
          color: $text-black;

        }
        .list {
          display: none;
          flex-direction: column;
          position: absolute;
          top: 100%;
          right: 0;
          background-color: $bg-wight;
          margin: 0;
          width: fit-content;


          .list-item {
            cursor: pointer;
            text-align: center;
            font-size: 18px;
            margin: 5px 0;
            padding: 10px 30px;
            &:hover {
              color: $color-red;
            }
          }
        }

        &:hover {
          .list {
            display: flex;

          }
        }
      }
    }

    @media (max-width: 400px) {
      .title {
        text-align: center;
      }
      .region {
        margin-bottom: 30px;
      }
      .wrapper {
        flex-direction: column;
      }
    }
  }
}

.active {
  display: flex;
}