/* Global variables for site Astex*/



/* Fonts */
$title-font: 'Roboto Condensed', sans-serif;
$text-font: Arial, sans-serif;


/* Colors */
$color-red: #EA1C2C;
$color-green: #0FBF00;

$text-black: #000000;
$text-dark-gray: #404041;
$text-light-gray: #939597;

$bg-wight: #FAFAFA;
$bg-black: #252525;

$gray: #CCCCCC;


/* Shadow and blur*/
$blur: blur(7px);
$shadow: 2px 6px 13px rgba(0, 0, 0, 0.07);