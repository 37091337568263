@import "variables";
@import "fonts";

/* General */

*{
  outline: none;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  padding: 0;
  margin: 0;

  width: 100%;

  overflow-x: hidden;

  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar
  {
    width: 12px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb
  {
    background-color: $color-red;

    &:hover{
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    }
  }
}

#astex {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
}

h1, h2, h3, h4, h5, h6, button, .quote, .button, .h1 {
  font-family: $title-font;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  text-align: left;

  span {
    font-family: $title-font;
    color: $color-red;
  }
}

p, li, a, span, input, textarea, label, ::placeholder, i {
  font-family: $text-font;
  text-align: left;

  transition: all 0.5s;
}

div, section, header, footer, button, input, textarea, a, span, .button {
  box-sizing: border-box;
}

section {
  width: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

/* Detail */

h1, .h1 {
  font-size: 64px;
  line-height: 90%;

  letter-spacing: 0.01em;

  @media (max-width: 769px) {
    font-size: 28px;
    line-height: 99%;
  }
}

h2 {
  font-size: 40px;
  line-height: 100%;

  letter-spacing: 0.01em;

  @media (max-width: 1000px) {
    font-size: 30px;
    line-height: 102%;
  }

  @media (max-width: 769px) {
    font-size: 24px;
    line-height: 102%;
  }
}

h3 {
  font-size: 30px;
  line-height: 150%;

  @media (max-width: 769px) {
    font-size: 20px;
    line-height: 102%;
  }
}

h4 {
  font-size: 24px;
  line-height: 120%;

  @media (max-width: 769px) {
    font-size: 16px;
    line-height: 135%;
  }
}

h5 {
  font-size: 20px;
  line-height: 130%;

  @media (max-width: 769px) {
    font-size: 12px;
    line-height: 100%;
  }
}

.quote {
  font-size: 20px;
  line-height: 130%;
}

p {
  font-size: 16px;
  line-height: 150%;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
}

button, .button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: fit-content;

  font-size: 24px;
  line-height: 120%;
  color: $bg-wight;

  padding: 9px 45px;

  background-color: $color-red;

  @media (max-width: 1281px) {
    font-size: 22px;
  }

  @media (max-width: 1000px) {
    font-size: 18px;
  }

  @media (max-width: 769px) {
    padding: 12px 45px;
  }
}

a {
  text-decoration: none;
}

.p-bold {
  color: $text-black !important;
  font-weight: bold;
  text-transform: uppercase;
}

.p-grey {
  color: $text-light-gray;

  p {
    color: $text-light-gray;
  }
}

.cost {
  color: $color-red !important;
  font-weight: bold;
}

.technical {
  font-size: 12px;
  line-height: 150%;
  color: $bg-wight;
}

.container {
  width: 1232px;
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1281px) {
    width: 992px;
  }

  @media (max-width: 1000px) {
    width: 768px;
  }

  @media (max-width: 769px) {
    width: 100%;

    padding: 0 16px;
  }
}

.container-full {
  width: 100%;

  display: flex;
  flex-wrap: wrap;

}

section {
  .container-full, .container {
    img, video {
      object-fit: contain;
    }
  }
}

.page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-1-4 {
  width: 25%;
  @media (max-width: 1000px) {
    width: 100%;
    padding-right: 100px;
    margin-bottom: 20px;
  }
  @media (max-width: 569px) {
    padding-right: 0px
  }
}

.column-3-4 {
  width: 75%;
  @media (max-width: 1000px) {
    width: 100%;
    //padding-left: 50px;

    p {
      padding-left: 50px;
    }
  }

  @media (max-width: 569px) {
    p {
      padding-left: 30px;
    }
  }
}

.column-1-3 {
  width: 33.333333333333333333%;
}


/* Header */

header {
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;

  z-index: 999999;

  #react-sidebar {
    .sidebar-main {
      overflow: hidden;
      height: calc(100vh - 72px);
      top: 72px;


      .sidebar-body {
        padding: 48px 24px;

        hr {
          background-color: #939597;
        }

        ul {
          li {
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;

            margin-right: 32px;

            color: $text-black;

            transition: all 0.2s;

            &:hover {
              color: $color-red;
              background-color: transparent !important;
            }

            svg {
              margin-left: 22px;
              width: 18px;
              height: 18px;
            }


            a {
              font-weight: bold;
              font-size: 16px;
              line-height: 150%;

              margin-right: 32px;

              color: $text-black;
              transition: all 0.5s;

              &:hover {
                color: $color-red;
              }
            }
          }
        }
      }
    }

    .sidebar-main.show {
      width: 100%;
    }

    .sidebar-main.second {
      height: 100vh;
      top: 0;
    }
  }


  .container {
    height: 100%;
    justify-content: space-between;
  }

  i {
    font-size: 24px;
  }

  .top {
    width: 100%;
    height: 72px;

    display: flex;
    align-items: center;

    background-color: $bg-wight;

    .left, .center, .right {
      width: 33.3333333333333%;

      display: flex;
    }

    .left {
      justify-content: flex-start;
      align-items: center;

      .catalog-menu, .mobile-menu {
        display: flex;
        align-items: center;

        cursor: pointer;

        &:hover {
          i {
            color: $color-red;
          }
        }

        i {
          margin-right: 12px;
        }
      }

      .catalog-menu{
        margin-right: 30px;
      }

      .ws-catalog-menu {
        color: $text-black;

        &:active {
          color: $color-red;
        }

        &:visited {
          color: $text-black;
        }
      }

      .logo-place {
        display: none;

        @media (max-width: 569px) {
          display: block;
          margin-left: 20px;
        }

        img {
          width: 140px;
        }
      }
    }

    .center {
      align-items: center;
      justify-content: center;

      @media (max-width: 569px) {
        display: none;
      }

    }

    .right {
      align-items: center;
      justify-content: flex-end;


      .search {
        cursor: pointer;

        &:hover {
          i {
            color: $color-red;
          }
        }
      }

      .favorite {
        margin-left: 36px;

        cursor: pointer;

        @media (max-width: 569px) {
          display: none;
        }

        &:hover {
          i {
            color: $color-red;
          }
        }
      }

      .cart {
        display: flex;
        align-items: center;

        margin-left: 36px;
        cursor: pointer;

        &>div{
          display: flex;
          align-items: center;
        }

        &:hover {
          i {
            color: $color-red;
          }
        }

        i {
          margin-right: 12px;
        }

        span {
          color: $text-black;
          font-weight: bold;
          font-size: 16px;
          line-height: 150%;

          .cost {
            color: $color-red;
            margin: 0 7px;
          }
        }
      }

    }
  }

  .bottom {
    width: 100%;
    height: 48px;

    background-color: $text-black;

    .left, .right {
      width: 50%;
      display: flex;
      align-items: center;
    }

    .left {
      justify-content: flex-start;
    }

    .right {
      justify-content: flex-end;

      ul {

        a {
          margin-left: 32px;
          margin-right: 0;


        }
      }

      .sub-menu {
        ul {
          a {
            margin-left: 0;
          }
        }
      }
    }


    .catalog-menu, .mobile-menu {
      display: flex;
      align-items: center;

      cursor: pointer;


      h4 {
        color: $bg-wight;
        transition: all 0.5s;
      }

      i {
        color: $bg-wight;
        margin-right: 12px;
      }

      &:hover {
        i, h4 {
          color: $color-red;
        }
      }
    }

    .center {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  ul {
    display: flex;
    align-items: center;

    height: 100%;

    li {
      height: 100%;
      display: flex;
      align-items: center;

      &:hover {
        a {
          color: $color-red;
        }
      }

      a {
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;

        margin-right: 32px;

        color: $bg-wight;

        @media (max-width: 1281px) {
          margin-right: 20px;
        }
      }
    }

    .has-sub-menu {
      position: relative;

      a {
        &:after {
          position: relative;
          content: url("../img/dropdown-arrow.svg");
          margin-left: 8px;
          bottom: 2px;
        }


      }

      &:hover {
        .sub-menu {
          display: block;
        }
      }

    }

    hr {
      border: none;
      border-left: 1px solid $text-light-gray;
      height: 40px;
      width: 1px;

      margin: 0 0 0 32px;

      box-sizing: border-box;
    }
  }

  .catalog-menu {

    .sub-menu-place {
      display: none;
      //padding-top: 120px;
      width: 100%;
      position: fixed;
      top: 120px;
      left: 0;

      z-index: 999999;
      justify-content: center;

      .sub-menu {
        display: flex;
        position: relative;
        width: 100%;
        top: 0;
        padding: 24px 0;

      }

      &:hover {
        display: flex;
      }


      .container {
        justify-content: space-between;
      }

      .column-1-4 {
        width: 23%;

        &:first-child {
          border-right: 1px solid $text-light-gray;
        }
      }
    }

    .sub-menu-place.active {
      display: flex;
    }

  }

  .sub-menu {
    display: none;
    position: absolute;
    top: 48px;
    padding: 24px;
    background-color: white;
    box-shadow: $shadow;

    //width: 266px;
    &:hover {
      display: block;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      flex-direction: column;
      align-items: flex-start;

      li {
        padding-left: 24px;
        margin-bottom: 12px;
        width: 100%;
        height: fit-content;
        a {
          color: $text-dark-gray;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          white-space: nowrap;

          &:after {
            display: none;
          }

          &:hover {
            color: $color-red;
          }
        }
      }

      li.title {
        padding-left: 0;

        a {
          font-weight: bold;
          font-size: 16px;
          line-height: 150%;
          text-transform: uppercase;

          color: $text-black;

          &:hover {
            color: $color-red;
          }
        }
      }
    }
  }

}

/* Footer */
footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $bg-black;
  padding: 64px 0 24px 0;

  position: relative;

  a[href^="tel:"] {
      color: $bg-wight!important;
  }

  @media (max-width: 769px) {
    padding-bottom: 20px;
  }

  p, li a {
    font-size: 12px;
    line-height: 150%;

    color: $gray;
  }

  a:hover {
    color: $color-red;
  }

  li a.title {
    font-size: 16px;
    line-height: 150%;

    color: $bg-wight;
  }

  .footer-logo {
    margin-bottom: 32px;
  }

  ul {
    width: 192px;
    margin: 0 16px 0 0;
    padding: 0;
    list-style: none;


    li {
      margin-bottom: 12px;
    }
  }

  .column-1-4 {
    display: flex;
    flex-direction: column;

    p {
      display: flex;

      font-size: 12px;
      line-height: 150%;

      margin-bottom: 7px;
      margin-top: 0;
      /* TEXT / Light gray */

      color: $bg-wight!important;

      a {
        color: $bg-wight!important;

        span {
          color: $bg-wight!important;
        }
      }

      span {
        color: $bg-wight!important;


        &:first-child {
          width: 70px;
        }

        &:last-child {
          color: $bg-wight!important;
        }
      }
    }

  }

  .column-3-4 {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 769px) {
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @media (max-width: 569px) {
      display: none;
    }
  }

  .contact-row {
    display: flex;
    margin-bottom: 12px;

    p {
      margin: 0
    }

    .left {
      width: 80px;
    }

    .right {
      display: flex;
      flex-direction: column;
    }
  }

  .social-row {
    margin-top: 12px;
    display: flex;

    a {
      margin-right: 16px;
    }

    a:hover {
      i {
        color: $color-red;
      }
    }

    i {
      font-size: 24px;
      color: $bg-wight;
    }
  }

  hr {
    margin-top: 48px;
    margin-bottom: 24px;
    width: 1344px;
    color: $text-light-gray;
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;

    @media (max-width: 769px) {
      flex-wrap: wrap;
    }

    span, a {
      color: $text-light-gray;
    }

    .left {
      @media (max-width: 569px) {
        display: flex;
        flex-wrap: wrap;
      }

      span {
        margin-right: 16px;
        @media (max-width: 769px) {
          margin-bottom: 10px;
        }
        @media (max-width: 569px) {
          width: 100%;
        }

        a:hover {
          color: $color-red;
        }
      }
    }

    .right {
      span {
        a {
          color: $color-red;
        }
      }
    }
  }
}

/* Slider */
#astex {
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin-left: 0;

      background-color: $bg-wight;

      &:hover {
        background-color: $color-red;
      }

      button {
        width: 100%;
        height: 100%;

        &:before {
          display: none;
        }
      }
    }

    .slick-active {
      background-color: $color-red;
    }
  }


  .slick-prev, .slick-next {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;

    z-index: 1;
    transform-origin: center;
    width: 20px;
    height: 20px;
  }

  .slick-prev {
    transform: rotate(180deg);

    &:before {
      display: block;
      width: 20px;
      height: 20px;
      content: url("../img/slider-arrow-red.svg");
    }
  }

  .slick-next {
    right: unset;
    transform: rotate(0deg);

    &:before {
      display: block;
      width: 20px;
      height: 20px;
      content: url("../img/slider-arrow-red.svg");
    }
  }

  .slick-prev.slick-disabled {
    transform: rotate(0deg);

    &:before {
      opacity: 1;
      content: url("../img/slider-arrow.svg");
    }
  }

  .slick-next.slick-disabled {
    transform: rotate(-180deg);

    &:before {
      opacity: 1;
      content: url("../img/slider-arrow.svg");
    }
  }
}


/* Product card */

.product-card {
  display: flex;
  flex-direction: column;
  width: 33%;

  .img-block {
    position: relative;

    height: 296px;
    width: 100%;

    background-position: center;
    background-size: cover;
    background-image: url("../img/product-card.png");
    background-color: white;

    margin-bottom: 16px;

    .favorite {
      position: absolute;
      bottom: 19px;
      right: 19px;

      cursor: pointer;


      i {
        font-size: 24px;
        color: $text-black;
      }

      &:hover {
        i {
          color: $color-red;
        }
      }
    }

  }
  .p-grey{
    height: 100px;
    overflow: hidden;
  }

  p{
    margin-top: 0!important;
  }
}

/* Pages */

.services-card {
  position: relative;

  width: calc(25% - 12px);
  height: 560px;

  margin-right: 16px;

  display: flex;
  flex-direction: column;

  background-size: cover;
  background-position: center;
  background-image: url("../img/door.svg");

  transition: all 0.5s;

  @media (max-width: 769px) {
    width: calc(50% - 6px);
    margin-right: 12px;
    margin-bottom: 12px;
    height: 460px;
  }

  @media (max-width: 569px) {
    width: 100%;
    margin-right: 0;
    height: 400px;

  }

  &:nth-child(2) {
    background-image: url("../img/window.svg");
    @media (max-width: 769px) {
      margin-right: 0;
    }
  }

  &:nth-child(3) {
    background-image: url("../img/things.svg");
  }

  &:last-child {
    margin-right: 0;
    background-image: url("../img/services.svg");

  }

  &:hover {
    background-size: cover;
  }

  .card-head {
    width: 100%;
    height: 186px;

    padding: 32px 24px;

    background-color: rgba(0, 0, 0, 0.1);

    @media (max-width: 569px) {
      height: 130px;
      padding: 20px;
    }
    p{
      font-size: 14px;
    }
  }

  .button {
    position: absolute;
    bottom: 32px;
    left: 24px;
  }
}

.ws-catalog {
  width: calc(33.3% - 12px);

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
}

.sub-cat-two-cards {
  width: calc(50% - 8px);

  &:nth-child(even) {
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.sub-cat-three-cards {
  width: calc(33.33% - 10.7px)
}

.sub-cat-four-cards {
  width: calc(25% - 12px)
}

.subcat {
  @media(max-width: 569px) {
    width: 100%;
  }
}
.category-card {
  width: calc(33.33% - 10.7px);
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%  !important;
    margin: 0 0 20px 0;
  }
}

.light {
  h2, p {
    color: $bg-wight;
  }
}

.dark {
  h2, p {
    color: $text-black;
  }


  p {
    font-size: 14px;
    line-height: 150%;
  }
}

.img-full {
  width: 100%;
  height: auto;
}

.partners {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 24px;
  padding-bottom: 40px;
  position: relative;

  .slick-slider {
    width: 100%;


  }

  .slider-navigation {
    position: absolute;
    bottom: 10px;
    right: 0;

    display: flex;

    .slick-next {
      margin-left: 20px;
    }
  }

  .single-partner {
    width: calc(25% - 10px);
    display: flex;
    flex-direction: column;

    @media (max-width: 769px) {
      width: calc(50% - 10px);
      margin-bottom: 12px;
    }

    img {
      width: auto;
      height: 108px;
      margin-bottom: 8px;
    }
  }
}

.single-type {
  display: flex;
  border-top: 1px solid $text-light-gray;
  padding-top: 24px;

  position: relative;

  &:hover {
    &:after {
      position: absolute;
      top: 31px;
      right: 50px;
      content: url("../img/call_made.png");
    }
  }

  .number {
    width: 45px;
    font-weight: bold;
    font-size: 30px;
    line-height: 150%;

    color: $text-light-gray;
  }

  .content {
    padding-left: 8px;
    padding-right: 100px;
  }

  h4 {
    color: $text-black;
    margin-top: 8px;
  }

  p {
    color: $text-dark-gray;
    margin-top: 8px;
    margin-bottom: 14px !important;
  }
}


/* Forms */

.for-form {
  display: flex;
  //flex-direction: column;
  //align-items: center;
  padding-top: 64px;

  p, label {
    font-size: 16px;
    line-height: 150%;
    color: $text-dark-gray;
    padding-left: 0;
  }

  p {
    margin-bottom: 40px !important;
    max-width: 800px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  input, textarea {
    border: 1px solid $text-light-gray;
    border-radius: unset;
    box-shadow: none;

    height: 48px;
    width: 608px;

    font-size: 16px;
    line-height: 150%;
    color: $text-dark-gray;

    box-sizing: border-box;

    padding: 12px 16px;
    margin-bottom: 24px;

    resize: none;
    overflow: hidden;

    @media (max-width: 769px) {
      width: 100%;
    }

    &::placeholder {
      font-size: 16px;
      line-height: 150%;
      color: $text-light-gray;
    }

    &:focus, &:active {
      border: 1px solid $text-dark-gray;
      border-radius: unset;
      outline: none;
    }
  }

  .not-valid {
    border: 1px solid $color-red;

  }

  .button, button {
    border: none;
    color: $bg-wight;

    width: 212px;

    margin-bottom: 0;

    cursor: pointer;
  }

  h2, h3 {
    color: $color-red;
    font-size: 40px;
    line-height: 100%;

    letter-spacing: 0.01em;

    @media (max-width: 1000px) {
      font-size: 30px;
      line-height: 102%;
    }

    @media (max-width: 769px) {
      font-size: 24px;
      line-height: 102%;
    }
  }
}

/*SuccessSubmit Modal*/

.ReactModal__Overlay {
  z-index: 99999999 !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.ReactModal__Content {
  border-radius: 0 !important;
  border: none !important;
  padding: 0 !important;

  .modal-content {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0;

    display: flex;
    flex-direction: column;

    .modal-content-top {
      width: 100%;
      padding: 24px 104px;
      background-color: $bg-wight;
    }

    .modal-content-bottom {
      width: 100%;
      padding: 24px 104px;

      display: flex;
      justify-content: flex-start;

      button {
        outline: none;
        border: none;
        cursor: pointer;
      }
    }

    .modal-close {
      color: $text-light-gray;
      transform: rotate(45deg);
      cursor: pointer;
      font-size: 24px;

      width: 18px;
      height: 18px;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 24px;
      right: 24px;

      &:hover {
        color: $color-red;
      }
    }
  }
}

/* Accordion */

.accordion {
  display: flex;
  cursor: pointer;
  transition: all 500ms;
  border-top: 1px solid $text-light-gray;
  padding: 22px 0;

  &:last-child {
    border-bottom: 1px solid $text-light-gray;
  }

  .content {
    width: calc(100% - 90px);

    h4 {
      font-size: 24px;
      line-height: 120%;
      color: $text-black;
      margin-top: 10px;

      @media (max-width: 569px) {
        font-size: 18px;
      }
    }

    .accordion-content {
      overflow: hidden;
      height: 0;
      transition: all 500ms;

      p {
        padding-left: 0;
      }
    }
  }

  .number {
    width: 45px;
    font-weight: bold;
    font-size: 30px;
    line-height: 150%;

    color: $text-light-gray;

    font-family: $title-font;
  }

  .plus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    color: $color-red;

    font-size: 40px;
    font-weight: bold;
  }
}

.accordion-visible {
  .content {
    .accordion-content {
      height: auto;
    }
  }

  .plus {
    width: 45px;
    color: $text-light-gray;
    transform: rotate(45deg);
  }
}

/*Reviews*/

.stars {
  display: flex;
  align-items: center;

  p {
    margin: 0 15px 0 0 !important;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;

    li {
      padding: 0;
      margin: 0 7px 0 0;

      content: url("../img/star.png");
    }
  }

  .help-info {
    margin-left: 20px;
    content: url("../img/help.png");
  }


}

.single-review {
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  .stars {

    ul {
      li {
        width: 10px;
      }
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */


      /* TEXT / Light gray */

      color: $text-light-gray;
      margin-left: 18px !important;
    }
  }
}


/*Socials*/

.socials {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  position: fixed;
  right: 0;
  top: 50%;

  z-index: 9999;

  li {
    padding: 0;
    margin: 0;

    i {
      font-size: 24px;
    }

    .icon-instagram {
      content: url("../img/instagram.png");
    }

    .icon-facebook {
      content: url("../img/facebook.png");
    }
  }
}


/*Preloader*/

.loader{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $bg-wight;
  z-index: 99999999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader--hide {
  display: none;
}

.sk-cube-grid {
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  width: 40px;
  height: 40px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $color-red;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}

.ReactModal__Body--open{
  overflow: hidden;
}