@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/fonts/icomoon.eot?bdidfc');
  src:  url('../fonts/icomoon/fonts/icomoon.eot?bdidfc#iefix') format('embedded-opentype'),
  url('../fonts/icomoon/fonts/icomoon.ttf?bdidfc') format('truetype'),
  url('../fonts/icomoon/fonts/icomoon.woff?bdidfc') format('woff'),
  url('../fonts/icomoon/fonts/icomoon.svg?bdidfc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\e902";
}
.icon-slider-arrow:before {
  content: "\e903";
  color: #939597;
}
.icon-slider-arrow-red:before {
  content: "\e904";
  color: #ea1c2c;
}
.icon-search:before {
  content: "\e905";
}
.icon-facebook-dark:before {
  content: "\e906";
  color: #fafafa;
}
.icon-favorite:before {
  content: "\e907";
}
.icon-instagram .path1:before {
  content: "\e908";
  color: rgb(241, 1, 118);
}
.icon-instagram .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-instagram .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-instagram .path4:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-instagram-dark:before {
  content: "\e90c";
  color: #fafafa;
}
.icon-logo .path1:before {
  content: "\e90d";
  color: rgb(234, 28, 44);
}
.icon-logo .path2:before {
  content: "\e90e";
  margin-left: -4.5849609375em;
  color: rgb(250, 250, 250);
}
.icon-logo .path3:before {
  content: "\e90f";
  margin-left: -4.5849609375em;
  color: rgb(234, 28, 44);
}
.icon-logo .path4:before {
  content: "\e910";
  margin-left: -4.5849609375em;
  color: rgb(234, 28, 44);
}
.icon-logo .path5:before {
  content: "\e911";
  margin-left: -4.5849609375em;
  color: rgb(234, 28, 44);
}
.icon-logo .path6:before {
  content: "\e912";
  margin-left: -4.5849609375em;
  color: rgb(234, 28, 44);
}
.icon-logo .path7:before {
  content: "\e913";
  margin-left: -4.5849609375em;
  color: rgb(234, 28, 44);
}
.icon-logo-dark .path1:before {
  content: "\e914";
  color: rgb(234, 28, 44);
}
.icon-logo-dark .path2:before {
  content: "\e915";
  margin-left: -4.625em;
  color: rgb(37, 37, 37);
}
.icon-logo-dark .path3:before {
  content: "\e916";
  margin-left: -4.625em;
  color: rgb(234, 28, 44);
}
.icon-logo-dark .path4:before {
  content: "\e917";
  margin-left: -4.625em;
  color: rgb(234, 28, 44);
}
.icon-logo-dark .path5:before {
  content: "\e918";
  margin-left: -4.625em;
  color: rgb(234, 28, 44);
}
.icon-logo-dark .path6:before {
  content: "\e919";
  margin-left: -4.625em;
  color: rgb(234, 28, 44);
}
.icon-logo-dark .path7:before {
  content: "\e91a";
  margin-left: -4.625em;
  color: rgb(234, 28, 44);
}
.icon-dropdown-arrow:before {
  content: "\e91b";
  color: #fafafa;
}
.icon-facebook .path1:before {
  content: "\e91c";
  color: rgb(23, 120, 242);
}
.icon-facebook .path2:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-facebook .path3:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(23, 120, 242);
}
.icon-cart:before {
  content: "\e900";
}
.icon-catalog-menu:before {
  content: "\e901";
}

.icon-linkedin{
  content: url("../img/linked.svg");
}

.icon-linkedin-dark{
  content: url("../img/linkedin-dark.svg");
  width: 24px;
}